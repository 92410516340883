<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <div class="list_page3">
            <div class="form_wrap">
              <div class="form">
                <ul class="form_ul">
                  <li class="form_li m_8">
                    <div class="form_in">
                      <div class="inline">
                        <label>
                          <input
                            name="build"
                            class="select"
                            :value="buildingName"
                            disabled
                          />
                        </label>
                      </div>
                    </div>
                  </li>
                  <li class="form_li">
                    <div class="form_in">
                      <div class="inline">
                        <label class="input">
                          <input
                            type="text"
                            placeholder=""
                            disabled
                            :value="unit"
                          />
                        </label>
                      </div>
                    </div>
                  </li>
                  <li class="form_li m_26">
                    <div class="form_ttl"><span>*</span>제목</div>
                    <div class="form_in">
                      <div class="inline">
                        <label class="input">
                          <input
                            type="text"
                            placeholder="제목"
                            v-model="form.title"
                          />
                        </label>
                      </div>
                      <div
                        class="hint"
                        :class="{ on: valid.form.title.$error }"
                      >
                        <p>제목을 입력해 주세요.</p>
                      </div>
                    </div>
                  </li>
                  <li class="form_li m_12">
                    <div class="form_ttl"><span>*</span>내용</div>
                    <div class="form_in">
                      <div class="inline">
                        <label class="input">
                          <textarea
                            placeholder="문의에 따라 답변 소요 시간이 길어질 수 있습니다."
                            class="memo"
                            v-model="form.contents"
                          ></textarea>
                        </label>
                      </div>
                      <div
                        class="hint"
                        :class="{ on: valid.form.contents.$error }"
                      >
                        <p>내용을 입력해 주세요.</p>
                      </div>
                    </div>
                  </li>
                  <img-input
                    :fileUrl="$ConstCode.getImagePath(form.imagePath)"
                    :file-paths="filePaths"
                    @file-change="fnFileChange"
                    @delete-image="fnDeleteImage"
                    multiple
                    :accepts="'image/jpeg, image/png'"
                  />
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="page_btn">
          <div class="btn_wrap">
            <button class="btn on" @click="fnSave">
              {{ form.resNo > 0 ? '수정하기' : '등록하기' }}
            </button>
            <!--입력되면 on-->
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { getCurrentInstance, reactive, toRefs, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import ImgInput from '@/components/views/image/ImgInput'

export default {
  name: 'menu-notice-tenant-write',
  components: { ImgInput },
  description: '입주민 소통공간 등록 페이지',
  setup() {
    const { proxy } = getCurrentInstance()
    const { getters } = useStore()
    const router = useRouter()
    const route = useRoute()
    const query = route.query

    const userData = computed(() => getters['user/getData'])

    const state = reactive({
      buildingName: userData.value.buildingName,
      unit: userData.value.unitName,
      form: {
        resNo: -1,
        title: '',
        contents: '',
        imagePath: '',
        imageFile: '',
        imageFiles: [],
        filePaths: []
      },
      filePaths: []
    })

    const fetchData = async () => {
      if (!proxy.$Util.isEmpty(query.resNo) || query.resNo > 0) {
        const res = await proxy.$ResNoticeSvc.fetchResNotice(query)
        if (res.code === 1) {
          state.form = res.entity.res
          state.filePaths = res.entity.filePaths
        }
      }
    }

    const rules = {
      form: {
        title: {
          required
        },
        contents: {
          required
        }
      }
    }

    const valid = useVuelidate(rules, state)

    const fnSave = async () => {
      valid.value.$touch()
      if (valid.value.$invalid) return
      const filePaths = await fnUploadImageFile()
      state.form.filePaths = state.filePaths
        .map(data => {
          if (!proxy.$Util.isEmpty(data.imagePath)) {
            return {
              storedFileName: data.imagePath,
              fileName: data.fileName
            }
          }
        })
        .concat(filePaths)
      if (state.filePaths.length > 3) {
        alert('이미지는 최대 3개 등록 가능합니다.')
        return false
      }
      if (!confirm('등록하시겠습니까?')) return false
      const res = await proxy.$ResNoticeSvc.upsertResNotice(state.form)
      if (res.code === 1) {
        alert('등록되었습니다.')
        await router.go(-1)
      }
    }

    const fnFileChange = files => {
      const result = []
      if (!proxy.$Util.isEmpty(state.filePaths)) {
        for (const data of state.filePaths) {
          result.push({
            imagePath: data.imagePath,
            fileName: data.fileName
          })
        }
      }
      if (proxy.$Util.isEmpty(files)) {
        state.filePaths = []
      } else {
        for (const file of files) {
          result.push({
            fileName: file.name,
            imagePath: file
          })
        }
        state.filePaths = result
      }
    }

    const fnDeleteImage = index => {
      state.filePaths.splice(index, 1)
    }

    const fnUploadImageFile = async () => {
      if (proxy.$Util.isEmpty(state.filePaths)) return []
      const files = state.filePaths
      const result = []
      for (const file of files) {
        if (!proxy.$Util.isEmpty(file) && typeof file.imagePath === 'object') {
          const res = await proxy.$CommonSvc.uploadFile({
            file: file.imagePath
          })
          result.push(res.entity)
        }
      }
      return result
    }

    fetchData()
    return {
      ...toRefs(state),
      fetchData,
      fnSave,
      valid,
      fnFileChange,
      fnDeleteImage,
      fnUploadImageFile,
      userData
    }
  }
}
</script>

<style scoped></style>
